/* Overlay.css */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's on top of all other content */
}

/* .spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left: 4px solid #fff;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
} */
