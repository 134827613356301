/* Hide scrollbar by default but maintain functionality */
#scrollable-element::-webkit-scrollbar {
  width: 1px;  /* Even thinner vertical scrollbar */
  height: 1px; /* Even thinner horizontal scrollbar */
  transition: opacity 0.3s;
  opacity: 0;
}

/* Show scrollbar on hover */
#scrollable-element:hover::-webkit-scrollbar {
  opacity: 0;
}

#scrollable-element::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
  display: none;  /* Hide the track */
  opacity: 0;
}

#scrollable-element::-webkit-scrollbar-thumb {
  background: #1d846f;
  border-radius: 20px;
  border: none;
  opacity: 0;
}

#scrollable-element::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 2px;
  opacity: 1;
}

/* For Firefox */
#scrollable-element {
  scrollbar-width: none; /* Hide by default */
}

#scrollable-element:hover {
  scrollbar-width: thin; /* Show on hover - 'thin' is the thinnest possible in Firefox */
  scrollbar-color: #1d846f transparent; /* Make track transparent in Firefox */
  border-radius: 3px;
}