:root {
	box-sizing: border-box;
	/* background-color: #f8f7f7; */
	--toastify-color-success: #027b5f;
	/* --chakra-colors-chakra-body-bg: #f8f7f7; */
	/* --toastify-color-error: #a0403a; */
}

body.no-scroll {
	overflow: hidden;
}

:root,
html,
body {
	/* --chakra-colors-chakra-body-bg: #f8f7f7; */

	box-sizing: border-box;
	display: flex;
	width: 100vw;
	height: auto;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	/* overflow-y: auto; */
	padding-right: 15px;
}

.AppContainer {
	box-sizing: border-box;

	text-align: start;
	/* background-color: #f8f7f7; */
	width: 100vw;
	height: auto;
	min-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;

	/* //! to make sure footer is at the bottom */
	padding: 0;
	margin: 0;
}

.navLink.active {
	/* color: #027b5f; */
	text-decoration: underline;
	/* text-fill
  -webkit-text-fill-color: transparent; */
}

.sunkenCard {
	/* background-color: #eaeef2; */
	/* background: var(--chakra-colors-default-bg); */
	transition:
		background-color 5s ease,
		transform 2s ease,
		box-shadow 2s ease;
}

.sunkenCard:hover {
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */

	/* #55 */
	box-shadow:
		rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
		rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
		rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

	/* #29 */
	/* box-shadow:
		rgba(0, 0, 0, 0.19) 0px 10px 20px,
		rgba(0, 0, 0, 0.23) 0px 6px 6px; */

	/* background-color: white; */
	background-color: var(--chakra-colors-card-bg-hover);
	/* transform: scale(1.005); */
	transform: translateY(-1px);
}

.raisedImage {
	/* background-color: #eaeef2; */
	/* background: var(--chakra-colors-default-bg); */
	/* filter: drop-shadow(0 0.2rem 0.25rem rgba(66, 65, 65, 0.664)); */

	transition:
		background-color 5s ease,
		transform 2s ease,
		filter 2s ease;
}
.raisedImage:hover {
	transform: scale(1.02);
	/* filter: drop-shadow(1px 10px 20px rgba(113, 112, 112, 0.09))
		drop-shadow(1px 6px 15px rgba(255, 255, 255, 0.782)); */
	/* filter: drop-shadow(0 0.2rem 0.25rem rgba(66, 65, 65, 0.664)); */
	/* filter: drop-shadow(0 0.2rem 0.25rem rgba(255, 255, 255, 0.664)); */
	filter: drop-shadow(0 0.2rem 0.5rem rgba(66, 65, 65, 0.664));
}
