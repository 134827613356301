body {
	margin: 0;
}

/* scrollbar.css */
::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 25px;
}

::-webkit-scrollbar-thumb {
	background-color: #888;
	/* background-color: #1d846f; */
	border-radius: 10px;
	border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
