:root {
	--toastify-color-info: #3d5a80;
	/* --toastify-color-info: #3498db; */
	--toastify-color-success: #1d846f;
	/* --toastify-color-success: #07bc0c; */
	/* --toastify-color-warning: #cda70f; */
	/* --toastify-color-warning: #f1c40f; */
	--toastify-color-warning: #e6c026;
	--toastify-color-error: #a63c32;
	/* --toastify-color-error: #e74c3c; */
}

.toastify-body {
	display: flex;
	align-items: center;
	text-align: left;
	padding-left: 10px;
	gap: 4px;
	font-size: medium;
	/* color: #f8f7f7; */
	font-weight: black;
	font-family: DIN_bold;

	/* font-family: monospace; */
	/* border-bottom-left-radius: "120deg"; */
	/* border-radius: 0; */
}

.joyride__button--next {
	background-color: "btn-active-bg" !important; /* Botão "Next" com fundo verde */
	color: white !important; /* Texto branco */
	border-radius: 5px; /* Bordas arredondadas */
}
